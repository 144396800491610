
.bodyForm {
  display: flex;
  flex-direction: column;
  margin-block-start: 15px;
  position: relative;
}

.labelDurationText {
  font: 500 16px/18px 'Ubuntu', sans-serif;
  padding-bottom: 5px;
}

.select {
  background-color: #FFFFFF;
  cursor: pointer;
  position: relative;
}

.selectHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #000000;
  background-color: #FFFFFF;
  padding: 10px;
  &:focus-visible {
    border: 2px solid #963FF2;
    outline: 0;
  }
}

.selected {
  border: 2px solid #963FF2;
}

.selectHeaderPrice {
  flex-grow: 1;
  text-align: end;
  margin-right: 10px;
}

.selectIcon {
  transition: transform .3s ease;
}

.activeIcon {
  transform: rotate(180deg);
}

.selectBody {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
}

.selectBodyItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  border: 2px solid #000000;
  background-color: #FFFFFF;
  padding: 10px;
  &:hover {
    background-color: #F4E7FF;
  }
  &:focus-visible {
    border: 2px solid #963FF2;
    outline: 0;
  }
}

.selectTextNormal {
  font: 400 14px/16px 'Ubuntu', sans-serif;
  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
}

.selectTextBold {
  font: 700 14px/16px 'Ubuntu', sans-serif;
}

.active {
  display: block;
}

.statusError {
  position: absolute;
  right: 0;
  padding: 2px 10px;
  font-size: 14px;
  color: #FFF;
  background-color: #F23F3F;
}

.statusErrorBorder {
  border: 2px solid #F23F3F;
}