.block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding-top: 70px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    gap: 40px;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding-top: 50px;
  }
  @media screen and (max-width: 480px) {
    align-items: stretch;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #963FF2;
  border: 6px solid #000000;
  box-shadow: inset -6px -6px 0px #6D3CE8;
  color: #FFFFFF;
  @media screen and (min-width: 480px) and (max-width: 767px) {
    min-width: 400px;
  }
}

.linkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.soc_img {
  width: 200px;
  height: 200px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 160px;
    max-height: 140px;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    max-width: 140px;
    max-height: 120px;
  }
  @media screen and (max-width: 480px) {
    max-width: 100px;
    max-height: 100px;
  }
}

.soc_link {
  font: 300 18px/20px 'Ubuntu', sans-serif;
  position: relative;
  text-align: center;
  display: flex;
}

.soc_link p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.soc_link img {
  padding-right: 8px;
  width: 32px;
  height: 24px;
}