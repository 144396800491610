// * {
//   outline : 1px solid red !important;
// }

@media screen and (min-width: 768px) and (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
}

.container {
  max-width: 1440px;
  width: 100%;
  padding: 0 150px;
  margin: 0 auto;
  flex: 1 0 auto;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 100px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 50px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    padding: 0 32px;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    padding: 0 16px;
  }

  @media (max-width: 319px) {
    padding: 0 8px;
  }
}







/* howplay.html */

.howplay__head {
  text-align: center;
  padding: 150px 0px 50px;
}

.howplay__body {
  color: #FFFFFF;
  font: 500 18px/21px 'Ubuntu', sans-serif;
  padding-bottom: 150px;
}

.howplay__body__items li {
  padding: 20px;
  background-color: #6D3CE8;
}

.howplay__body__items li a {
  font: 300 18px/21px 'Ubuntu', sans-serif;
}

.howplay__body__items li span {
  user-select: text;
  text-decoration: underline;
}

.howplay__body__items li:nth-child(-n+11) {
  margin-bottom: 25px;
}




/* Buttons */

.button {
  padding: 11px 48px;
  background-color: #4300D2;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}

.button-width {
  width: 100%;
}

.button-type_primary {
  background-color: #E83CCE;
  border: 1px solid #000000;
  box-shadow: inset -6px -5px 0px rgba(0, 0, 0, 0.25), inset 6px 5px 0px rgba(255, 255, 255, 0.5);
}

.select {
  background-color: #FFFFFF;
  cursor: pointer;
  position: relative;
}

.select__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #963FF2;
  background-color: #FFFFFF;
  padding: 10px;
}

.select__header-price {
  flex-grow: 1;
  text-align: end;
  margin-right: 10px;
}

.select__header-icon {
  transition: transform .3s ease;
}

.select__text-normal {
  font: 400 14px/16px 'Ubuntu', sans-serif;
}

.select__text-bold {
  font: 700 14px/16px 'Ubuntu', sans-serif;
}

.select__body {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
}

.select.active .select__body {
  display: block;
}

.select.active .select__header-icon {
  transform: rotate(180deg);
}

.select__body__item {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  border: 2px solid #000000;
  background-color: #FFFFFF;
  padding: 10px;
}

.select__body__item:hover {
  background-color: #F4E7FF;
} 
