.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 1;
}

.donate {
  padding-bottom: 60px;
  > h2 {
    font: 700 36px/42px 'Ubuntu', sans-serif;
    padding-bottom: 40px;
  }
}

.titleCard {
  display: flex;
  justify-content: space-between;
  > h2 {
    font: 700 36px/42px 'Ubuntu', sans-serif;
    padding-bottom: 40px;
  } 
}

.sortBody {
  width: 100%;
  max-width: 280px;
  position: relative;
  > button {
    font-size: 18px;
    font-weight: 700;
    > span {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.listSort {
  position: absolute;
  z-index: 1;
  top: 35%;
  right: 10%;
  > li {
    padding: 5px 10px;
    background-color: #eaeaea;
    border-bottom: 1px solid #000;
  }
}

.wrapCard {
  display: flex;
  justify-content: flex-start;
  gap: 45px;
  align-items: center;
  flex-wrap: wrap;
  color: #FFFFFF;
  @media screen and (max-width: 450px) {
    justify-content: center;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  
  max-width: 350px;
  max-height: 485px;

  color: #FFF;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.947917) 68.75%, rgba(0, 0, 0, 0.985632) 75%, #000000 83.85%);
  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  @media screen and (max-width: 400px) {
    max-width: 280px;
    max-height: 415px;
  }
}

.absentCards {
  max-width: 350px;
}

.cardText {
  padding: 0 40px 40px;
  > h2 {
    font: 700 36px/42px 'Ubuntu', sans-serif;
    margin-bottom: 20px;
    @media screen and (max-width: 400px) {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 250px 30px 30px 30px;
  }
}

.btn {
  padding: 12px 48px;
  width: 100%;
  background-color: #E83CCE;
  border: 1px solid #000;
  box-shadow: inset -6px -6px 0px rgba(0, 0, 0, 0.25), inset 6px 6px 0px rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}