.header {
  display: flex;
  padding-top: 32px;
  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}

.blockLeft {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.blockIp {
  position: relative;
  padding: 8px 20px 8px 40px;
  background: linear-gradient(92deg, #963FF2 0%, #6D3CE8 100%);
  border: 1px solid #000000;
  box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.4);
  color: #FFFFFF;
  user-select: none;
  cursor: pointer;
  p {
    font: 500 14px/16px 'Ubuntu', sans-serif;
  }
  &::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 6px;
    width: 20px;
    height: 20px;
    background-image: url(../../../images/icon-ip.svg); 
  }
}
 
.blockTitle {
  padding: 20px 0 16px;
  font: 700 48px/56px 'Ubuntu', sans-serif;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 36px;
  }
  @media screen and (max-width: 767px) {
    font-size: 28px;
  }
}

.blockText {
  font: 400 24px/32px 'Ubuntu', sans-serif;
  padding-bottom: 50px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font: 400 20px/28px 'Ubuntu', sans-serif;
  }
  @media screen and (max-width: 767px) {
    font: 400 18px/28px 'Ubuntu', sans-serif;
  }
}

.blockInfo {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  @media screen and (max-width: 479px) {
    width: 100%;
    justify-content: space-between;
  }
}

.blockLinkPlay {
  padding-right: 12px;
  a {
    padding: 12px 48px;
    background-color: #E83CCE;
    border: 1px solid #000000;
    box-shadow: inset -6px -5px 0px rgba(0, 0, 0, 0.25), inset 6px 5px 0px rgba(255, 255, 255, 0.5);
    @media screen and (max-width: 479px) {
      padding: 12px 32px;
    }
  }
  @media screen and (max-width: 479px) {
    padding-right: 0;
  }
}

.blockInfoOnline {
  position: relative;
  padding: 10px 10px 10px 44px;
  background: linear-gradient(92deg, #963FF2 0%, #6D3CE8 100%);
  border: 1px solid #000000;
  p {
    font: 500 18px/20px 'Ubuntu', sans-serif;
  }
  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    top: 5px;
    background: url(../../../images/icon-online.svg) no-repeat;
    background-position: center;
  }
}

.blockRight {
  max-width: 60%;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 55%;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
