
.swiper {
  display: flex;
  max-width: 765px;
  max-height: 150px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.sliderTitle {
  padding: 150px 0 40px;
  text-align: center;
  > h2 {
    font: 700 36px/42px 'Ubuntu', sans-serif;
    @media screen and (max-width: 576px) {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 125px 0 40px;
  }
  @media screen and (max-width: 576px) {
    padding: 100px 0 40px;
  }
  @media screen and (max-width: 420px) {
    padding: 75px 0 40px;
  }
}

// Slide
.swiperSlide {
  background-color: #6D3CE8;
  position: relative;
}

.cubeSwap {
  position: absolute;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 109px;
  }
  @media screen and (min-width: 576px) {
    width: 120px;
    height: 89px;
  }
  @media screen and (max-width: 576px) {
    width: 120px;
    height: 89px;
  }
  @media screen and (max-width: 420px) {
    width: 100px;
    height: 79px;
  }
}

.textSlide {
  color: #fff;
  padding: 28px 130px 28px 60px;
  z-index: 1;
  position: relative;
  > h2 {
    font: 700 24px/28px 'Ubuntu', sans-serif;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 14px;
    }
    @media screen and (max-width: 576px) {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    @media screen and (max-width: 420px) {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
  > p {
    font: 500 16px/18px 'Ubuntu', sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 14px;
    }
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
    @media screen and (max-width: 420px) {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 28px 90px 28px 60px;
  }
  @media screen and (max-width: 576px) {
    padding: 20px 40px;
  }
  @media screen and (max-width: 420px) {
    padding: 20px;
  }
}

.swapImg {
  width: 25%;
  height: 95%;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 992px) {
    height: 90%;
  }
  @media screen and (max-width: 576px) {
    height: 80%;
  }
}

.sliderBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  font: 400 12px/14px 'Ubuntu', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  > img {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  @media screen and (max-width: 992px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 40px 0;
  }
}