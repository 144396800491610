.swiper-pagination {
  position: relative;
}

.swiper-pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #A49BB1;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: auto;
  bottom: 0;
  top: 0;
  left: 0;
}

.swiper-pagination-bullet-active {
  width: 24px;
  height: 10px;
  border-radius: 5px;
  background: #9451F8;
  transition: width 0.3s ease, height 0.3s ease, border-radius 0.3s ease, background-color 0.3s ease;
}
