.admin {
  display: flex;
}

.wrap {
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.labelTitle {
  margin-block: 18px 6px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.durationGroup {
  display: flex;
  gap: 50px;

  .inputGroup input {
    margin-bottom: 6px;
    &::placeholder {
      color: #999;
      font-size: 10px;
    }
  }
}

.privilegeInputs {
  .inputGroup {
    .buffer {
      display: flex;
      gap: 10px;
      margin-bottom: 6px;
    }

    .button {
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #fff;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #0056b3;
      }
    }

    .addPrivilege {
      margin-top: 12px;
    }
  }
}

.fileInput {
  margin-bottom: 10px;
}

.submitBtn {
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: #218838;
}



// EditDonateStatus

.editorDonate {
  margin-block: 60px;
  h2 {
    text-align: center;
    margin-bottom: 12px;
  }
}

.listData {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0;
}

.listDataBody {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  li {
    margin-bottom: 2px;
    padding-bottom: 8px;
    border-bottom: 2px solid #000;
    &:last-child {
      border-bottom: none;
    }
  }
}

.editorBtns {
  display: flex;
  text-align: center;
  gap: 5px;
  .btn {
    width: 50%;
    color: white;
    padding: 10px 16px;
  }
}

.editBtn {
  background-color: #4caf50;
  border-radius: 5px;
  &:hover {
    background-color: #0ba01f;
  }
}

.deleteBtn {
  background-color: #d32f2f;
  border-radius: 5px;
  &:hover {
    background-color: #af1515;
  }
}


// ===================

.goBack {
  text-align: center;
  margin-top: 20px;
  .goBackBtn {
    background-color: #2196f3;
    color: white;
    padding: 12px 32px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #0d47a1;
    }
  }
}

.getUsersContainer {
  margin-block: 25px;
}

.getUsersTitle {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.getUsersButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.getUsersButton:hover {
  background-color: #0056b3;
}



.listUrls {
  list-style: none;
  padding: 0;
}

.buttonAdminPage {
  display: inline-block;
  padding: 10px 20px;
  margin-block: 5px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.buttonAdminPage:hover {
  background-color: #0056b3;
}



// AddArtifact
.selectedImageContainer {
  margin-block: 20px;
  max-width: 300px;
}

.selectedImage {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}
