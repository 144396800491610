// NewsSlider.jsx
.paginat {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.icon {
  padding: 0 10px;
  background: rgba(150, 63, 242, 0.15);
  cursor: pointer;
  > img {
    width: 38px;
    height: 38px;
  }
}

.prev {
  rotate: 180deg;
}

.disabled {
  visibility: hidden;
  cursor: default;
}

// NewsForSlider.jsx
.card {
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  background: #963ff2;
  border: 3px solid #000000;
  box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.5), inset 4px 4px 0px rgba(0, 0, 0, 0.5);
  background-image: url(./../../../../images/info-cube.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  color: #FFFFFF;
  @media (max-width: 479px) {
    background-size: 160px;
  }
}

.cardHead {
  display: flex;
  flex-direction: column;
  > h2 {
    font: 700 24px/28px "Ubuntu", sans-serif;
    @media (max-width: 479px) {
      font: 700 16px/20px "Ubuntu", sans-serif;
    }
  }
  > p {
    padding: 15px 0 20px;
    font: 500 16px/18px "Ubuntu", sans-serif;
    @media (max-width: 479px) {
      font: 500 14px/16px "Ubuntu", sans-serif;
    }
  }
}

.cardButton {
  padding: 12px 40px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #000000;
  background: #E83CCE;
  font: 700 16px/18px "Ubuntu", sans-serif;
  box-shadow: inset -6px -5px 0px rgba(0, 0, 0, 0.25), inset 6px 5px 0px rgba(255, 255, 255, 0.5);
  @media (max-width: 479px) {
    padding: 12px 20px;
    font: 700 14px/18px "Ubuntu", sans-serif;
  }
}

.date {
  padding: 6px 36px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.25);
  font: 700 16px/18px "Ubuntu", sans-serif;
  @media (max-width: 479px) {
    padding: 6px 18px;
    font: 700 14px/18px "Ubuntu", sans-serif;
  }
}