.body {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  color: #FFFFFF;
  font-family: "Ubuntu", sans-serif;
  justify-content: center;
  padding-top: 50px;
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    padding-top: 40px;
  }
}

.card {
  min-height: 240px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
  background: #963ff2;
  border: 3px solid #000000;
  box-shadow: inset -8px -8px 0px rgba(0, 0, 0, 0.5), inset 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-image: url(./../../../images/info-cube.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.cardHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    font: 500 20px/28px "Ubuntu", sans-serif;
  }
  > p {
    padding: 6px 20%;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.25);
    font: 700 16px/18px "Ubuntu", sans-serif;
  }
}

.cardButton {
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  border: 1px solid #000000;
  background: #E83CCE;
  font: 700 16px/18px "Ubuntu", sans-serif;
  box-shadow: inset -6px -5px 0px rgba(0, 0, 0, 0.25), inset 6px 5px 0px rgba(255, 255, 255, 0.5);
}

.little {
  max-width: 300px;
  text-align: center;
}

.big {
  flex-grow: 2;
  width: 40%;
  max-width: 500px;
  .cardHead {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > p {
      margin-bottom: 0;
    }
  }
  .cardButton {
    width: 60%;
  }
}

@media (max-width: 850px) {
  .cardHead > p {
    padding: 6px 10%;
  }
  .big .cardButton {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .sliderNews {
    display: none;
  }
}

@media (max-width: 767px) {
  .body {
    display: none;
  }
  .sliderNews {
    display: block;
    padding-top: 30px;
  }
}