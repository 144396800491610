//! Заняться и переписать стили!

.Image {
  max-width: 320px;
  height: auto;
}



.snglEditTitle {
  font-size: 28px;
  text-align: center;
  margin-top: 64px;
}

.bodyForm {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 32px 128px;
}

.label {
  font-weight: bold;
  color: #333;
}


.input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-block: 6px 14px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
}

.snglEditFrom {
  width: 100%;
  margin-right: 32px;
}


.goBack {
  text-align: center;
  margin-block: 20px 40px;
  .goBackBtn {
    background-color: #2196f3;
    color: white;
    padding: 12px 32px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #0d47a1;
    }
  }
}

.submitEdit {
  display: flex;
  justify-content: center;
  width: 40%;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 16px auto 0;

  &:hover {
    background-color: #218838;
  }
}