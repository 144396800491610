.radioWrapper {
  display: flex;
  align-items: center;
}

.radioInput {
  display: none;
}

.radioLabel {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.square {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  transition: background-color 0.3s ease;
}

.checked {
  background-color: #6D3CE8;
}
