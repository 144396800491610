.rulesTitle {
  text-align: center;
  padding: 150px 0 50px;
  > h2 {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 992px) {
    padding-top: 125px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 576px) {
    padding-top: 80px;
  }
}

.rulesBody {
  color: #FFFFFF;
  padding-bottom: 150px;
  font: 500 18px/22px 'Ubuntu', sans-serif;
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 80px;
  }
}

.noRules {
  text-align: center;
  padding: 150px 0;
  color: #000;
  font: 500 18px/22px 'Ubuntu', sans-serif;
  > h2 {
    margin-bottom: 16px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px 0;
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    padding: 80px 0;
  }
}

.ruleItem.active .headImg {
  transform: rotate(0.5turn);
}

.ruleItem.active .body {
  display: block;
}

.head {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6D3CE8;
  cursor: pointer;
  border-bottom: 1px solid #BE81FF;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.headImg {
  width: 12px;
  height: 8px;
  transition: transform .3s ease;
}

.body {
  display: none;
  > li {
    padding: 12px 20px;
    background-color: #963FF2;
    border-bottom: 1px solid #BE81FF;
    font: 400 16px/18px 'Ubuntu', sans-serif;
    user-select: text;
    @media screen and (max-width: 768px) {
      padding: 12px 18px;
      font-size: 14px;
    }
    p + p {
      margin-top: 8px; // Добавляем отступ между тегами <p>
    }
  }
}