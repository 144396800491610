// Обнуление стрелочек в input number
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}
//=====

.modalWindow {
  width: 100%;
  max-width: 1200px;
  max-height: 730px;
  display: flex;
  padding: 80px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../../assets/images/ShopPage/background-shopPage.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 1400px) {
    max-width: 1050px;
    padding: 60px;
  }
  @media screen and (max-width: 1100px) {
    max-width: 850px;
    padding: 40px;
  }
  @media screen and (max-width: 990px) {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 80px 100px;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    background-image: url('../../../assets/images/ShopPage/background-shopPage_mobile.png');
    overflow-y: auto;
    flex-direction: column;
    background-size: cover;
  }
  @media screen and (max-width: 890px) {
    padding: 70px 90px;
  }
  @media screen and (max-width: 766px) {
    padding: 60px;
  }
  @media screen and (max-width: 580px) {
    padding: 40px;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 20px;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.closeIcon {
  position: absolute;
  z-index: 4;
  top: -5%;
  right: -2%;
  > img {
    width: 45px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      width: 35px;
    }
    @media screen and (max-width: 400px) {
      width: 30px;
    }
  }
  @media screen and (min-width: 991px) {
    display: none;
  }
}

.modalRigth {
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  > h2 {
    color: #FFF;
    font: 700 28px/32px 'Ubuntu', sans-serif;
    padding-bottom: 30px;
  }
  > p {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    font: 400 14px 'Ubuntu', sans-serif;
  }

  .titlePrice {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    font: 400 18px 'Ubuntu', sans-serif;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 1400px) {
    padding-right: 60px;
  }
  @media screen and (max-width: 1100px) {
    padding-right: 40px;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
}

.mobModalRight {
  position: relative;
  @media screen and (min-width: 991px) {
    display: none;
  }
  > h2 {
    color: #FFF;
    font: 700 28px/32px 'Ubuntu', sans-serif;
    padding-bottom: 20px;
    @media screen and (max-width: 450px) {
      font-size: 18px;
      line-height: 18px;
      padding-bottom: 12px;
    }
  }
  > p, h3 {
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    font: 400 14px 'Ubuntu', sans-serif;
    margin-bottom: 20px;
    @media screen and (max-width: 450px) {
      margin-bottom: 16px;
      font-size: 12px;
      margin-bottom: 12px;
    }
  }

  > h3 {
    margin-bottom: 0;
    padding: 16px 16px 8px;
    font: 400 18px/20px 'Ubuntu', sans-serif;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
}

.descTA > p, h3 {
  font: 400 18px/20px 'Ubuntu', sans-serif;
}

.privilege {
  flex-grow: 1;
  margin-top: 20px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: auto; 
  .privilegeList {
    padding-top: 10px;
    list-style: disc;
    list-style-position: inside;
    > li {
      font: 400 14px/20px 'Ubuntu', sans-serif;
      padding-bottom: 5px;
      @media screen and (max-width: 450px) {
        padding-bottom: 0px;
      }
    }
  }
}

.mobModalPrivilege {
  @media screen and (min-width: 991px) {
    display: none;
  }
}

.modalLeft {
  min-width: 380px;
  display: flex;
  flex-direction: column;
  > h2 {
    color: #FFF;
    font: 700 28px/32px 'Ubuntu', sans-serif;
    padding-bottom: 30px;
    @media screen and (max-width: 450px) {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 580px) {
    min-width: 100%;
  }
}

.modalForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 14px;
  background-color: rgba(255, 255, 255, 0.8);
}





.formQuest {
  display: flex;
  padding-top: 12px;
  align-items: center;
  > img {
    width: 40px;
    height: 40px;
    @media screen and (max-width: 380px) {
      display: none;
    }
  }
}

.formQuestText {
  margin-left: 20px;
  background-color: #AB44DB;
  padding: 8px 12px;
  color: #FFF;
  font: 400 12px/14px 'Ubuntu', sans-serif;
  > a {
    padding-top: 2px;
    display: flex;
    color: #FFC0F6;
    text-align: center;
    > img {
      margin-right: 4px;
      align-items: center;
    }
  }
  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 380px) {
    margin: 0;
  }
}

.formOffer {
  display: flex;
  align-items: center;
  padding-top: 20px;
  > label {
    white-space: nowrap;
    > a {
      color: #963FF2;
    }
    ::before {
      content: "\00a0";
    }
  }
}

.publicOffer {
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:checked+label::before {
    cursor: pointer;
    background-size: 8px 8px;
    background-image: url("../../../assets/images/icons/user-agreement-icon.svg");
    @media screen and(max-width: 380px) {
      background-size: 6px 6px;
    }
  }
  // стили при наведении на checkbox курсором 
  &:not(:disabled):not(:checked)+label:hover::before {
    cursor: pointer;
  }
  // стили для активного состояния чекбокса (при нажатии на него)
  &:not(:disabled):active+label::before {
    background-color: rgba(109, 60, 232, 0.5);
  }
  // в состоянии disabled 
  &:disabled+label::before {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
}

.publicOffer+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font: 400 16px/16px 'Ubuntu', sans-serif;
  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #000;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
}

.btn {
  margin-top: 10px;
  padding: 12px 0px;
  color: #FFF;
  font: 700 16px 'Ubuntu', sans-serif;
  background-color: #E83CCE;
  border: 1px solid #000;
  box-shadow: inset -6px -6px 0px rgba(0, 0, 0, 0.25), inset 6px 6px 0px rgba(255, 255, 255, 0.5);
}

.btn[disabled] {
  opacity: 0.5;
  cursor: auto;
}

// ModalInput

.inputWrapper {
  margin-block-start: 15px;
  position: relative;
}

.formInputText {
  width: 100%;
  box-sizing: border-box;
  background-color: #FFF;
  border: 2px solid #000;
  padding: 8px;
  text-align: center;
  &::placeholder {
    font: 400 14px/16px 'Ubuntu', sans-serif;
    color: #000;
    opacity: 0.5;
    @media screen and (max-width: 380px) {
      font-size: 12px;
    }
  }
  &:focus {
    border: 2px solid #963FF2;
    outline: 0;
  }
}

.wrapperLabel {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inputLabel {
  font: 500 16px/18px 'Ubuntu', sans-serif;
}

// ModalInputs
.labelSpan {
  position: absolute;
  right: 0;
  padding: 2.5px 10px;
  font-size: 14px;
  color: #FFF;
}

.labelSpanBackgroundValid {
  background-color: #963FF2;
}

.inputBorderValid {
  border: 2px solid #963FF2;
  outline: 0;
}

.labelSpanBackgroundError {
  background-color: #F23F3F;
}

.inputBorderError {
  border: 2px solid #F23F3F;
  outline: 0;
}

.checkImg {
  width: 39px;
  height: 39px;
  position: absolute;
  right: 0%;
  bottom: 0%;
  padding: 5px;
  cursor: pointer;
}




.wrapperInput {
  position: relative;
}

.btnMinus {
  left: 0;
  margin-left: 4px;
}

.btnPlus {
  right: 0;
  margin-right: 4px;
}

.btnMinus:active, .btnPlus:active {
  transform: translateY(-50%) scale(0.5);
}

.button {
  width: 24px;
  height: 24px;
  padding: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.2s ease;
  &:focus-visible {
    border: 2px solid #963FF2;
    outline: 0;
  }
}
